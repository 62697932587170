import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { graphql } from "gatsby";
import inlineImagesReducer from "../util/inlineImagesReducer";

import Resources from "../layouts/Resources";

function ResourcesPage({ data, pageContext }) {
  const components = {
    Resources,
  };
  const inlineImages = inlineImagesReducer(data.mdx.frontmatter.inlineImages);
  return (
    <MDXProvider components={components}>
      <MDXRenderer pageContext={pageContext} data={data} inlineImages={inlineImages}>
        {data.mdx.body}
      </MDXRenderer>
    </MDXProvider>
  );
}

export default ResourcesPage;

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        inlineImages {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
    resources: allFile(filter: { dir: { regex: "/.*resources.*/" } }) {
      edges {
        node {
          name
          prettySize
          dir
          extension
          relativePath
          publicURL
        }
      }
    }
  }
`;
